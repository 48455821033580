import DropDownMenu_ShinyMenuIIButtonSkinComponent from '@wix/thunderbolt-elements/src/components/DropDownMenu/viewer/skinComps/ShinyMenuIIButtonSkin/ShinyMenuIIButtonSkin.skin';


const DropDownMenu_ShinyMenuIIButtonSkin = {
  component: DropDownMenu_ShinyMenuIIButtonSkinComponent
};


export const components = {
  ['DropDownMenu_ShinyMenuIIButtonSkin']: DropDownMenu_ShinyMenuIIButtonSkin
};


// temporary export
export const version = "1.0.0"
